export default function Faq() {
  return (
    <section
      id="faq"
      className="text-gray-600 bg-gray-100 body-font overflow-hidden"
    >
      <div className="container px-20 py-16 mx-auto">
        <div className=" divide-gray-100">
          <h1 className="text-3xl font-medium title-font text-gray-900 text-center mb-12">
            FAQs
          </h1>
          <div className="py-2 flex flex-wrap md:flex-nowrap text-left">
            <div className="md:flex-grow">
              <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
                Why should I subscribe to HustleReach?
              </h2>
              <p className="leading-relaxed">
                HustleReach began as an internal product that we build to fulfil
                our own needs. We spent countless of hours constructing a lead
                pipeline and we know how painful it is. We want you to save
                time, energy, connect to the right developers and improve your
                chance of success. This is why we are only interested to help
                you make the relevant connections.
              </p>
            </div>
          </div>
          <div className="py-2 flex flex-wrap md:flex-nowrap text-left">
            <div className="md:flex-grow">
              <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
                Where do you gather your leads from?
              </h2>
              <p className="leading-relaxed">
                Github, Stack Overflow, HackerRank, LeetCode and many more! We
                fuse data from various sources and present them as a single
                view. No more visiting multiple profiles just to check out a
                single individual.
              </p>
            </div>
          </div>
          <div className="py-2 flex flex-wrap md:flex-nowrap text-left">
            <div className="md:flex-grow">
              <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
                How do you ensure that the leads are relevant?
              </h2>
              <p className="leading-relaxed">
                We build this product with relevancy in mind. This is why we
                never offer the entire database for sale, only a subset that
                fulfils your requirement.
              </p>
            </div>
          </div>
          <div className="py-2 flex flex-wrap md:flex-nowrap text-left">
            <div className="md:flex-grow">
              <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
                Do you have a free trial?
              </h2>
              <p className="leading-relaxed">
                Email us at{" "}
                <a href="mailto:office@hustlereach.co" className="underline">
                  office@hustlereach.co
                </a>{" "}
                with your requirement and we will send you a sample list to try
                out.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
