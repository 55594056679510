import hustleReachLogo from "../img/email.svg";

export default function Footer() {
  return (
    <footer className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <a
            href="/#"
            className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
          >
            <div className="w-6 inline-flex items-center justify-center flex-shrink-0">
              <img src={hustleReachLogo} alt="email" />
            </div>
            <span className="ml-3 text-xl">HustleReach</span>
          </a>
          <p className="mt-2 text-sm text-gray-500">
            Made with ❤️ by DC and YX
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/3 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              PRODUCT
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="https://airtable.com/shrTJdrNCsmAFSGDU"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Demo
                </a>
              </li>
              <li>
                <a
                  href="#statistic"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Statistics
                </a>
              </li>
              <li>
                <a
                  href="#pricing"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Pricing
                </a>
              </li>
              <li>
                <a href="#faq" className="text-gray-600 hover:text-gray-800">
                  FAQs
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              LEGAL
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a href="/#" className="text-gray-600 hover:text-gray-800">
                  Terms and Condition
                </a>
              </li>
              <li>
                <a href="/#" className="text-gray-600 hover:text-gray-800">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/#" className="text-gray-600 hover:text-gray-800">
                  Affiliate Program
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              INFORMATION
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a href="/#" className="text-gray-600 hover:text-gray-800">
                  About Us
                </a>
              </li>
              <li>
                <a href="/#" className="text-gray-600 hover:text-gray-800">
                  Contact Us
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left">
            © 2021 HustleReach
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            <a
              href="https://twitter.com/hustlereach"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500"
            >
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a
              href="mailto:office@hustlereach.co"
              className="ml-3 text-gray-500"
            >
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
              </svg>
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}
