import fireLogo from "../img/fire.svg";
import personLogo from "../img/person.svg";
import clockLogo from "../img/clock.svg";

export default function Feature1() {
  return (
    <section className="text-gray-600 bg-gray-100 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Why should I subscribe?
          </h1>
          <p className="text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Instantly find relevant developers with all the data you need in one
            place.
          </p>
          <p className="text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Built for <span className="underline">tech recruiters</span>,{" "}
            <span className="underline">tech marketers</span> and fellow{" "}
            <span className="underline">indie hackers</span> 🤟.
          </p>
          {/* <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
          </div> */}
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0">
              <img src={clockLogo} alt="fire" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Save 100+ hours googling or searching on LinkedIn
              </h2>
              <p className="leading-relaxed text-base">
                Filter developers by location, skillsets and get their email and
                social profiles
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0">
              <img src={personLogo} alt="fire" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Access fresh leads of relevant Software Developers
              </h2>
              <p className="leading-relaxed text-base">
                Database updates continuously, aggregating data from multiple
                platforms
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0">
              <img src={fireLogo} alt="fire" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Promote your product, get feedback and find new users
              </h2>
              <p className="leading-relaxed text-base">
                Reach out to like-minded developers to validate your ideas
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
