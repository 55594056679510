export default function Hero() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-20 mx-auto">
        <div className="text-center mb-10">
          <h1 className="title-font sm:text-5xl text-3xl mb-4 font-bold text-gray-900">
            Reach out to
            <br className="hidden lg:inline-inline" />
            <span className="text-indigo-500"> software developers </span>
            with ease
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
            Contact millions of skilled software developers. Details delivered
            in your inbox. Every single week.
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 md:space-y-0 space-y-6">
          <iframe
            title="Airtable demo"
            className="w-full h-screen/2 object-center rounded"
            src="https://airtable.com/embed/shr6pADy3Luc5EdI1"
          ></iframe>
          <p className="text-xs italic leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
            Data in the table above is masked for demonstration purpose. You
            will have access to full data after subscribing.
          </p>
        </div>

        <button className="flex mx-auto mt-10 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
          Send me leads every week
        </button>
      </div>
    </section>
  );
}
