import "./App.css";
import { Helmet } from "react-helmet";

import Feature1 from "./components/Feature1";
import Statistic from "./components/Statistic";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Pricing from "./components/Pricing";
import Testimonial from "./components/Testimonial";
import Faq from "./components/Faq";

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>HustleReach</title>
        <link rel="icon" type="image/png" href="favicon.png" />
      </Helmet>

      <Header />
      <Hero />
      <Feature1 />
      <Statistic />
      <Pricing />
      <Testimonial />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
