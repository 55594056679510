import hustleReachLogo from "../img/email.svg";

export default function Header() {
  return (
    <header className="text-gray-600 body-font">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a
          href="/#"
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
        >
          <div className="w-6">
            <img src={hustleReachLogo} alt="email" />
          </div>
          <span className="ml-3 text-xl">HustleReach</span>
        </a>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
          <a href="/#features" className="mr-5 hover:text-gray-900">
            Features
          </a>
          <a href="/#statistic" className="mr-5 hover:text-gray-900">
            Statistic
          </a>
          <a href="/#pricing" className="mr-5 hover:text-gray-900">
            Pricing
          </a>
          <a href="/#testimonial" className="mr-5 hover:text-gray-900">
            Testimonials
          </a>
        </nav>
        <button
          className="inline-flex items-center border-2 border-indigo-600 py-1 px-3 focus:outline-none hover:-translate-y-0.5 transform transition rounded text-base mt-4 md:mt-0"
          type="button"
        >
          <a
            href="https://demo.hustlereach.co"
            target="_blank"
            rel="noreferrer"
          >
            Demo
          </a>
        </button>
      </div>
    </header>
  );
}
