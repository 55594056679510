// import emailLogo from "../img/email.svg";
import worldLogo from "../img/world.svg";
import northAmericaLogo from "../img/north-america.svg";
import europeLogo from "../img/europe.svg";
import asiaLogo from "../img/asia.svg";

export default function Statistic() {
  return (
    <section id="statistic" className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-16">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Current Statistics
          </h1>
          <p className="text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            We gather developer profiles from multiple platform, enrich them and
            present them in a single view. Statistics below is updated on a
            monthly basis.
          </p>
        </div>
        <div className="flex flex-wrap -m-4 text-center">
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <div className="w-20 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0">
                <img src={worldLogo} alt="email" />
              </div>
              <h2 className="title-font font-medium text-3xl text-gray-900">
                1.1 million
              </h2>
              <p className="leading-relaxed">Developers Worldwide</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <div className="w-20 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0">
                <img src={northAmericaLogo} alt="email" />
              </div>
              <h2 className="title-font font-medium text-3xl text-gray-900">
                523k
              </h2>
              <p className="leading-relaxed">Noth America</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <div className="w-20 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0">
                <img src={europeLogo} alt="email" />
              </div>
              <h2 className="title-font font-medium text-3xl text-gray-900">
                315k
              </h2>
              <p className="leading-relaxed">Europe</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <div className="w-20 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0">
                <img src={asiaLogo} alt="email" />
              </div>
              <h2 className="title-font font-medium text-3xl text-gray-900">
                287k
              </h2>
              <p className="leading-relaxed">Asia</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
